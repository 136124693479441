select,
input,
textarea {
	font-family: inherit;
	width: 100%;
}

.fcc-header {
	background-color: #e6e8e8;
	display: flex;
	align-items: center;
	padding: 0 10px 0 20px;
	height: 50px;
}

.fcc-header-actions {
	display: none;
	margin-left: auto;
}

.fcc-wrap:hover .fcc-header-actions {
	display: block;
}

.fcc-header-actions button {
	border-radius: 50%;
	padding: 0;
	border: 0;
	width: 30px;
	height: 30px;
}

.fcc-header-actions button:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.field-type-page .fcc-header {
	background-color: #FF572B;
	color: #fff;
}

.field-type-section .fcc-header {
	background-color: #268FBB;
	color: #fff;
}

table {
	width: 100%;
}

td,
.fcc-empty {
	border-color: #EDEDED;
	border-width: 1px 0 0 1px;
	text-align: left;
	border-style: solid;
}

.fcc-empty {
	text-align: center;
	padding: 50px;
}

.fcc-fields {
	border-color: #EDEDED;
	border-width: 0 1px 1px 0;
	border-style: solid;
	margin-top: 20px;
	vertical-align: top;
}

.fcc-input .fcc-fields {
	margin-top: 0;
}

.fcc-label {
	padding: 15px 12px;
	margin: 0;
	background: #F9F9F9;
	width: 200px;
	vertical-align: top;
}

.fcc-input {
	padding: 15px 12px;
	vertical-align: top;
}

.fcc-fields-footer {
	display: flex;
}

.fcc-fields-footer button {
	margin-left: auto;
}

.fcc-label-instructions {
	color: #777;
	font-size: 13px;
}

.fcc-input-flex {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.fcc-input-flex > div {
	width: calc( 50% - 6px );
}

.fcc-input-flex-3 > div {
	width: calc( 33.3333% - 6px );
}
