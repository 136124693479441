@font-face {
	font-family: Circular;
	font-weight: 300;
	src:
		url( "./fonts/circular-light.woff2" ) format( "woff2" ),
		url( "./fonts/circular-light.woff" ) format( "woff" );
}

@font-face {
	font-family: Circular;
	font-weight: 400;
	src:
		url( "./fonts/circular-book.woff2" ) format( "woff2" ),
		url( "./fonts/circular-book.woff" ) format( "woff" );
}

@font-face {
	font-family: Circular;
	font-weight: 500;
	src:
		url( "./fonts/circular-medium.woff2" ) format( "woff2" ),
		url( "./fonts/circular-medium.woff" ) format( "woff" );
}

@font-face {
	font-family: Circular;
	font-weight: 700;
	src:
		url( "./fonts/circular-bold.woff2" ) format( "woff2" ),
		url( "./fonts/circular-bold.woff" ) format( "woff" );
}

@font-face {
	font-family: Circular;
	font-weight: 900;
	src:
		url( "./fonts/circular-black.woff2" ) format( "woff2" ),
		url( "./fonts/circular-black.woff" ) format( "woff" );
}

body {
	font-family: Circular, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
