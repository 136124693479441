.fcc-form {
	margin: 0 auto;
	max-width: 1200px;
	padding: 50px;
}

.fcc-json {
	background-color: #F9F9F9;
	padding: 20px;
	overflow: auto;
}
