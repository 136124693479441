.fcc-load {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.9);
	padding: 50px;
}

.fcc-load-form {
	max-width: 1100px;
	margin: 0 auto;
}

.fcc-load textarea {
	background: #fff;
	font-family: monospace;
	font-size: 16px;
	height: calc( 100vh - 150px );
	padding: 20px;
	vertical-align: bottom;
	width: 100%;
}

.fcc-load-has-error textarea {
	height: calc( 100vh - 203px );
}

.fcc-load-footer {
	display: flex;
}

.fcc-load-cancel {
	background-color: #333;
	color: #fff;
	padding: 10px 20px;
	border: 0;
	border-radius: 3px;
	margin-top: 20px;
	margin-left: auto;
}

.fcc-load-error {
	background-color: #bf1616;
	color: #fff;
	padding: 15px;
}

.fcc-load-submit {
	background-color: #165ebf;
	color: #fff;
	padding: 10px 20px;
	border: 0;
	border-radius: 3px;
	margin-left: 10px;
	margin-top: 20px;
}
